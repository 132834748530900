import styled from 'styled-components';

import { Container } from '@/components/core/flexbox';

const OuterWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.neutral.default};
`;

const OuterContainer = styled(Container)`
  padding-top: 136px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 104px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 64px;
    padding-right: 0px;
  }
`;

export { OuterWrapper, OuterContainer };
