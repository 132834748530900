import styled, { css } from 'styled-components';
import getImgSrcWorkaround from '@/utils/images';
import Image from 'next/image';

const ImageSwitch = ({ image }) => (
  <ImageContainer empty={!image}>
    {image && (
      <CustomImage
        data-testid="image-switch"
        src={getImgSrcWorkaround(image?.data?.attributes.url)}
        alt={image.data.attributes.name}
        fill
        sizes="(max-width: 767px)  100vw,
               (max-width: 991px) 60vw,
               727px"
      />
    )}
  </ImageContainer>
);

export default ImageSwitch;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 727px;
  height: 449px;
  border-radius: 4px;

  ${(props) =>
    props.empty &&
    css`
      background-color: ${(props) => props.theme.colors.neutral.default};
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 344px;
    width: 60%;
    justify-content: flex-start;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    height: 188px;
    display: block;
    margin-bottom: 24px;
  }
`;

const CustomImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
