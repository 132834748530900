'use client';

import { useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';

import { Col, Row } from '@/components/core/flexbox';
import { Box, BoxInner } from '@/components/individuals/WhatWeTreat/Box';
import { OuterContainer, OuterWrapper } from '@/components/individuals/WhatWeTreat/Container';
import PainDescriptions from '@/components/individuals/WhatWeTreat/Descriptions';
import PainPoints from '@/components/individuals/WhatWeTreat/PainPoints';
import { LargeCircleShape } from '@/components/individuals/WhatWeTreat/Shapes';
import { Label, Title } from '@/components/core/Title';
import {
  ImageOuterWrapper,
  ImageWrapper,
  ModelAndDescriptionsWrapper,
} from '@/components/individuals/WhatWeTreat/ModelImage';

const HeaderWrapper = styled.div`
  padding-bottom: 56px;
  max-width: 620px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 535px;
  }

  & ${Label} {
    max-width: 511px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 16px;
    padding-right: 24px;
  }
`;

const InnerWrapper = styled(Row)`
  position: relative;
  overflow: hidden;

  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    border-radius: 8px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    background-color: ${(props) => props.theme.colors.white};
    padding-top: 88px;
    padding-bottom: 80px;
  }
`;

const DescriptionWrapper = styled(Col)`
  align-items: center;
  justify-content: center;
  padding-left: 80px;
  z-index: 10;
  order: 1;

  h4 {
    font-weight: 700;
    color: ${(props) => props.theme.colors.grey.dark};
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 16px;
    max-width: 300px;
  }

  p {
    font-family: ${(props) => props.theme.font.family.body};
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    max-width: 220px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    h4 {
      padding-top: 80px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    h4 {
      max-width: 300px;
    }

    p {
      max-width: 220px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    order: 2;

    h4 {
      font-size: 25px;
      line-height: 32px;
      max-width: 100%;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      max-width: 100%;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-right: 80px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    order: 1;
    padding-left: 0;
    padding-bottom: 40px;
    padding-right: 24px;

    h4 {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 24px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
`;

export default function WhatWeTreat({ content }) {
  const [selectedPain, selectPain] = useState('shoulder');
  if (!content) return null;
  const { title, subtitle, description, options } = content;

  return (
    <OuterWrapper>
      <OuterContainer>
        {title && (
          <HeaderWrapper>
            <Title data-testid="title" size="2xl">
              {title}
            </Title>
          </HeaderWrapper>
        )}
        <InnerWrapper>
          <Box>
            <BoxInner>
              <LargeCircleShape />
            </BoxInner>
          </Box>

          <DescriptionWrapper xs={12} md={12} lg={4}>
            {subtitle && <h4 data-testid="subtitle">{subtitle}</h4>}
            {description && <p data-testid="description">{description}</p>}
          </DescriptionWrapper>

          <ImageOuterWrapper xs={12} md={12} lg={8}>
            <ModelAndDescriptionsWrapper>
              <ImageWrapper>
                <Image
                  data-testid="image"
                  src="/assets/individuals/what-we-treat.png"
                  alt=""
                  className="pains-model"
                  width={316}
                  height={800}
                  sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, 300px"
                />
                <PainPoints selectedPain={selectedPain} selectPain={selectPain} />
              </ImageWrapper>
              <PainDescriptions
                options={options}
                selectedPain={selectedPain}
                selectPain={selectPain}
              />
            </ModelAndDescriptionsWrapper>
          </ImageOuterWrapper>
        </InnerWrapper>
      </OuterContainer>
    </OuterWrapper>
  );
}
