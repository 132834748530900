import styled from 'styled-components';

import { Icon } from '@/components/shared/Icon';

const FlexItem = styled.div`
  flex: 1;
  flex-basis: 19%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-basis: 45%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-basis: 100%;
  }
`;

const CustomIcon = styled(Icon)`
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin: auto;
  }
`;

export default function Card({ icon, title, description }) {
  return (
    <FlexItem>
      {icon && <CustomIcon image={icon} />}
      <h4>{title}</h4>
      <div>{description}</div>
    </FlexItem>
  );
}
