import styled from 'styled-components';
import { UpperText } from '@/components/core/Title';
import { Section } from '@/components/core';

const Wrapper = styled(Section)`
  position: relative;
  padding-top: 136px;
  & ${UpperText}, h2 {
    text-align: center;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.colors.white};
    z-index: -2;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    padding-top: 104px;
    & ${UpperText}, h2 {
      text-align: left;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 64px;
  }
`;

export default Wrapper;
