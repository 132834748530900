export const PAIN_CONDITIONS = [
  'neck',
  'shoulder',
  'elbow',
  'lowerBack',
  'hip',
  'wrist',
  'hand',
  'knee',
  'ankle',
];
