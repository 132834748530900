import styled from 'styled-components';

const StepListWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 358px 390px auto;
  margin-top: 88px;

  & *:first-child {
    grid-column: 2;
    grid-row: 1 / 2;
  }
  & *:nth-child(2) {
    grid-column: 3;
    grid-row: 2 / 3;
  }
  & *:nth-child(3) {
    grid-column: 2;
    grid-row: 3;
  }
  & *:nth-child(4) {
    grid-column: 1;
    grid-row: 2 / 3;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;
    flex-flow: row wrap;
    row-gap: 64px;
    justify-content: space-between;
    margin-top: 64px;
  }
`;

export default StepListWrapper;
