import { Container } from '@/components/core/flexbox';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

import StepListWrapper from './StepListWrapper';
import Step from './Step';
import Decoration from './Decoration';
import MobileSteps from './MobileSteps';
import Intro from '../Intro';

export default function HowItWorks({ content, shadowColor }) {
  const { label, title, description, steps } = content;

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  return (
    <Container>
      <Intro title={title} upperLabel={label} description={description} innerSpacer />
      {isMobile ? (
        <MobileSteps shadowColor={shadowColor} steps={steps} />
      ) : (
        <StepListWrapper>
          {steps.map((step) => (
            <Step key={step.id} {...step} shadowColor={shadowColor} />
          ))}
          <Decoration />
        </StepListWrapper>
      )}
    </Container>
  );
}
