'use client';

import { CTA, HeaderBackground, Layout } from '@/components/core';
import { Section } from '@/components/core';
import {
  EndToEnd,
  WhatWeTreat,
  Results,
  PelvicPainConditions,
  OurSolutions,
  HowItWorks,
  GetStarted,
  Testimonials,
} from '@/components/individuals';
import FAQ from '@/components/shared/FAQ';
import Footnotes from '@/components/shared/footnotes';

function PageContent({ bannerInfo, content }) {
  const {
    header,
    endToEnd,
    whatWeTreat,
    pelvicPain,
    ourSolutions,
    howItWorks,
    resultsList,
    getStarted,
    testimonials,
    faq,
    cta,
    footnotes,
  } = content;

  return (
    <Layout bannerInfo={bannerInfo} dynamicNavbarLayout>
      {header && <HeaderBackground content={header} md="0" sm="0" xs="0" titleSize="4xl" />}
      {endToEnd && <EndToEnd content={endToEnd} />}
      {whatWeTreat && <WhatWeTreat content={whatWeTreat} />}
      {pelvicPain && <PelvicPainConditions content={pelvicPain} />}
      {ourSolutions && <OurSolutions content={ourSolutions} />}
      {howItWorks && <HowItWorks content={howItWorks} />}
      {resultsList && <Results content={resultsList} />}
      {getStarted && <GetStarted content={getStarted} />}
      {testimonials && <Testimonials content={testimonials} />}
      {faq && (
        <Section>
          <FAQ
            header={{ title: faq.title, description: faq.subtitle, upperLabel: faq.upperLabel }}
            accordion={faq.accordion}
          />
        </Section>
      )}
      {cta && <CTA secondary {...cta} />}
      {footnotes && <Footnotes footNotes={footnotes} />}
    </Layout>
  );
}

export default PageContent;
