import { useId } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 118px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 247px;
    height: 247px;
  }

  ${(props) =>
    props.$type === 'firstShape' &&
    css`
      right: 50%;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        transform: translateX(-50%) rotate(45deg);
        top: 51px;
        left: 50%;
      }
    `};

  ${(props) =>
    props.$type === 'secondShape' &&
    css`
      left: 50%;
      transform: rotate(90deg);

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        transform: rotate(135deg);
        top: 290px;
        left: unset;
        right: 40px;
      }
    `};

  ${(props) =>
    props.$type === 'thirdShape' &&
    css`
      top: 500px;
      left: 50%;
      transform: rotate(180deg);

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        transform: translateX(-50%) rotate(225deg);
        top: unset;
        bottom: 260px;
        left: 50%;
      }
    `};
  ${(props) =>
    props.$type === 'fourthShape' &&
    css`
      top: 500px;
      right: 50%;
      transform: rotate(270deg);

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        transform: rotate(315deg);
        top: 290px;
        left: 40px;
      }
    `};
`;

const SvgQuarterEllipse = ({ id = 'firstShape' }) => {
  const svgID = useId(id);
  return (
    <Wrapper $type={id}>
      <svg
        aria-hidden="true"
        width="392"
        height="392"
        viewBox="0 0 392 392"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g style={{ mixBlendMode: 'multiply' }}>
          <path
            d="M5 386.5C5 175.803 175.803 5 386.5 5"
            stroke={`url(#${svgID})`}
            strokeWidth="10"
            strokeLinecap="round"
            strokeDasharray="1 45"
          />
        </g>
        <defs>
          <radialGradient
            id={svgID}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(125.013 106.617) rotate(41.8595) scale(350.504)"
          >
            <stop stopColor="#E7D8CE" />
            <stop offset="1" stopColor="#F7F4F2" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </Wrapper>
  );
};

export default SvgQuarterEllipse;
