'use client';

import * as Sentry from '@sentry/nextjs';

import { Container } from '@/components/core/flexbox';
import CustomAccordion from './CustomAccordion/CustomAccordion';
import GraphicElements from './GraphicElements';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFaqByCategoryAndProduct } from '@/services/faqs';
import { NeutralButton } from '@/components/core/Button';
import Intro from '../Intro';
import { StyledContent, CategoryList, CustomLoading } from './styles';
import { scrollToSection } from '@/utils/scrollToSection';
import { useSearchParams } from 'next/navigation';

export default function Wrapper({
  header,
  accordion,
  categories,
  initialContentFaq,
  product,
  withoutGraphic = false,
  large = false,
  full = false,
}) {
  const params = useSearchParams();
  const category = params.get('category');
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(categories?.[0]?.name || '');
  const [selectedFaq, setSelectedFaq] = useState(initialContentFaq || accordion || []);

  const changeCategory = useCallback(
    async (name, previousCategory) => {
      try {
        setIsCategoryLoading(true);
        setSelectedCategory(name);
        const faqByCategory = await getFaqByCategoryAndProduct(name, product);
        setSelectedFaq(faqByCategory.length ? faqByCategory : []);
      } catch (error) {
        // revert category on error
        setSelectedCategory(previousCategory);
        Sentry.captureException(error);
      } finally {
        setIsCategoryLoading(false);
      }
    },
    [product],
  );

  useMemo(() => {
    if (!category) return;

    const format = category?.toLowerCase();
    const item = categories?.find((item) => item.displayName.toLowerCase() === format);

    if (item) {
      changeCategory(item?.name, selectedCategory);
    }
  }, [category]);

  useEffect(() => {
    if (!category) return;

    scrollToSection({ sectionId: 'faqs', isReduced: true });
  }, []);

  if (!initialContentFaq && !accordion) return null;

  return (
    <Container id="faqs">
      {header && <Intro {...header} innerSpacer />}
      <StyledContent $large={large}>
        {categories && categories.length > 1 && (
          <CategoryList>
            {categories.map((item) => (
              <li key={item.id}>
                <NeutralButton
                  $active={selectedCategory === item.name}
                  onClick={() => changeCategory(item.name, selectedCategory)}
                  data-testid="button"
                  type="button"
                >
                  <span style={{ opacity: isCategoryLoading ? 0 : 1 }}>{item.displayName}</span>
                  {isCategoryLoading && <CustomLoading />}
                </NeutralButton>
              </li>
            ))}
          </CategoryList>
        )}
        {!withoutGraphic && <GraphicElements />}
        {selectedFaq && <CustomAccordion accordion={selectedFaq} full={full} />}
      </StyledContent>
    </Container>
  );
}
