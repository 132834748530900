import HowItWorksComponent from '@/components/shared/HowItWorks';
import { Section } from '@/components/core';

function HowItWorks({ content }) {
  return (
    <Section>
      <HowItWorksComponent content={content} />
    </Section>
  );
}

export default HowItWorks;
