'use client';

import { useState } from 'react';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

import Switcher from './Switcher';

import Slider from './Slider';

const Steps = ({ entries }) => {
  const [step, setStep] = useState(1);
  const mobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  if (!entries || !entries.length) return null;

  if (mobile) return <Slider entries={entries} />;

  return <Switcher entries={entries} setStep={setStep} step={step} />;
};

export default Steps;
