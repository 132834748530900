import React, { useId, useMemo } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';

import Content from '@/components/core/Accordion/Content';
import ItemTitle from '@/components/solutions/sword360/SeamlessSetup/ItemTitle';

const TitleWrapper = styled.h3`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &[aria-expanded='true'] {
    margin-bottom: 24px;
  }
`;

const TitleText = styled.span`
  color: #747986;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  padding-right: 16px;

  &.is-open {
    color: ${(props) => props.theme.colors.grey.dark};
  }
`;

const TitleIcon = styled.i`
  min-width: 15px;
  min-height: 15px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 2px;
    background-color: #a4aab6;
    border-radius: 120px;
    transition: transform 500ms;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(270deg);
  }

  &.is-open:after {
    transform: translate(-50%, -50%) rotate(0deg);
    background-color: #1d3861;
  }
`;

const ContentWrapper = styled.div`
  color: ${(props) => props.theme.colors.grey.dark};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;

  &:after {
    content: '';
    height: 8px;
    width: 100%;
    display: block;
    opacity: 0;
  }
`;

const AccordionItemStyled = styled.li`
  padding: 24px 0;
  border-bottom: 1px solid ${(props) => (props.transparentBorders ? 'transparent' : '#e7eaf0')};

  &:first-child {
    border-top: 1px solid ${(props) => (props.transparentBorders ? 'transparent' : '#e7eaf0')};
  }
`;

export default function AccordionItem({
  className,
  id,
  title,
  children,
  expanded = false,
  handleClick,
  indexedTitle = false,
  index,
  transparentBorders,
  wrapContent = true,
  titleComponent,
  ...rest
}) {
  const uniqueId = useId();
  const labelId = useId();
  const panelId = useMemo(() => `panel-${id || 'accordion'}-${uniqueId}`, [id, uniqueId]);
  const Container = useMemo(() => (wrapContent ? Content : React.Fragment), [wrapContent]);
  const TitleContainer = useMemo(() => titleComponent || TitleText, [titleComponent]);

  return (
    <AccordionItemStyled className={className} transparentBorders={transparentBorders} {...rest}>
      {title && (
        <TitleWrapper
          className="title-wrapper"
          id={labelId}
          aria-controls={panelId}
          aria-expanded={expanded}
          onClick={() => handleClick()}
          role="button"
          tabIndex={0}
        >
          {indexedTitle ? (
            <ItemTitle className={cn({ 'is-open': expanded })} title={title} index={index} />
          ) : (
            <>
              <TitleContainer className={cn({ 'is-open': expanded })} as="span">
                {title}
              </TitleContainer>
              <TitleIcon className={cn({ 'is-open': expanded })} aria-hidden="true" />
            </>
          )}
        </TitleWrapper>
      )}

      <AnimateHeight
        id={panelId}
        aria-labelledby={labelId}
        aria-hidden={!expanded}
        duration={500}
        height={expanded ? 'auto' : 0}
        data-testid="contentWrapper"
      >
        <ContentWrapper className="content-wrapper">
          <Container>{children}</Container>
        </ContentWrapper>
      </AnimateHeight>
    </AccordionItemStyled>
  );
}
