import styled from 'styled-components';

import { Col } from '@/components/core/flexbox';

const TextCol = styled(Col)`
  align-content: center;
  display: grid;
  justify-items: start;
  gap: 24px;
  max-width: 456px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 16px;
  }
`;

export default TextCol;
