import styled from 'styled-components';
import { CircleFilled } from '@/components/core/Circle';
import GradientRingShape from '@/public/assets/shapes/gradient_ring_shape.svg';
import theme from '@/utils/styles-variables';

const CustomGradientRingShape = styled(GradientRingShape)`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    display: block;
    position: absolute;
    top: -95px;
    left: -378px;
    width: 787px;
    height: 787px;
  }
`;

const CustomCircleFilled = styled(CircleFilled)`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    display: block;
    position: absolute;
    bottom: -44px;
    right: -91px;
    z-index: 0;
    width: 219px;
    height: 219px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    bottom: -50px;
    right: -55px;
    z-index: 0;
    width: 303px;
    height: 303px;
  }
`;

export default function GraphicElements() {
  return (
    <>
      <CustomGradientRingShape />
      <CustomCircleFilled color={theme.colors.secondary.light} />
    </>
  );
}
