import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  padding: 136px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    text-align: left;
    padding: 104px 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 64px 0;
  }
`;

export default Wrapper;
