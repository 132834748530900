import Wrapper from './Wrapper';
import Header from './Header';
import Steps from './Steps';

const GetStarted = ({ content }) => {
  if (!content) return null;
  const { upperLabel, title, description, entries } = content;

  if (!entries || !entries.length) return null;

  return (
    <Wrapper>
      <Header upperLabel={upperLabel} title={title} description={description} />

      <Steps entries={entries} />
    </Wrapper>
  );
};

export default GetStarted;
