import styled from 'styled-components';

const Box = styled.div`
  position: absolute;
  top: 72px;
  bottom: 55px;
  left: 0;
  right: 0;
`;

const BoxInner = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    overflow: visible;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export { Box, BoxInner };
