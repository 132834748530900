import styled from 'styled-components';
import Image from 'next/image';

import getImgSrcWorkaround from '@/utils/images';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 251px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 275px;
  height: 251px;
  filter: drop-shadow(0px 20px 50px ${(props) => props.shadowColor || 'rgba(29, 56, 97, 0.1)'});

  img {
    max-width: 100%;
    object-fit: contain;
  }
`;

const Name = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  margin-top: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 18px;
    margin-top: 24px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  max-width: 338px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 320px;
  }
`;

function Step({ name, description, image, shadowColor, isMobile }) {
  return (
    <Wrapper>
      {!isMobile && (
        <ImageWrapper shadowColor={shadowColor}>
          <Image
            src={getImgSrcWorkaround(image?.data?.attributes.url)}
            alt={image?.data?.attributes.alternativeText || ''}
            width={275}
            height={251}
          />
        </ImageWrapper>
      )}
      <Name>{name}</Name>
      <Description>{description}</Description>
    </Wrapper>
  );
}

export default Step;
