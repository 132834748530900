import styled from 'styled-components';

const ConditionList = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  margin-right: 83px;
  gap: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    gap: 24px;
    justify-content: center;
    margin-top: 64px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 312px;
    display: block;
    margin-left: -24px;
    margin-right: -24px;
  }
`;

export default ConditionList;
