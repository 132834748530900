import styled, { css } from 'styled-components';
import { Accordion, AccordionItem } from '@/components/core/Accordion';
import Spinner1PxAnimated from '@swordhealth/ui-corporate/icons/system/spinner-animated-1px.svg';

export const CustomAccordionWrapper = styled(Accordion)`
  max-width: 962px;
  margin: auto;
  position: relative;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 8px;
    border: 1px solid #e7eaf0;
  }

  ${(props) =>
    props.full &&
    css`
      max-width: 100%;
    `};
`;

export const customAccordionSpacings = css`
  padding: 16px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding: 27px 64px;
  }
`;

export const customAccordionItemStyles = css`
  padding: 0;
  position: relative;

  .title-wrapper {
    ${customAccordionSpacings}

    i {
      min-height: 30px;
      min-width: 30px;
      padding: 7.5px;
      background-color: ${(props) => props.theme.colors.light_blue};
      border-radius: 50%;

      &:before,
      &:after {
        background-color: ${(props) => props.theme.colors.black};
        height: 1px;
      }
    }

    &[aria-expanded='true'] {
      margin-bottom: 0;
    }
  }

  .content-wrapper {
    ${customAccordionSpacings}

    @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
      padding-top: 0;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
`;

export const CustomAccordionItem = styled(AccordionItem)`
  ${customAccordionItemStyles}

  &:first-child {
    border-top: 1px solid transparent;
  }
`;
export const StyledContent = styled.div`
  position: relative;
  margin: auto;
  max-width: ${(props) => (props.$large ? '100%' : '962px')};
`;
export const CategoryList = styled.ul`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: initial;
    padding: 0 24px 0 36px;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-left: 24px;
  }
`;
export const CustomLoading = styled(Spinner1PxAnimated)`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
`;
