import QueryString from 'qs';

import cmsClient from '@/lib/cms-client';

export async function getFaqPage() {
  const query = {
    populate: ['seo', 'seo.image', 'header', 'header.title', 'header.subtitle', 'product'],
  };
  const data = await cmsClient(
    `/faq-page?${QueryString.stringify(query)}`,
    {
      next: { revalidate: 120 },
    },
    true,
  );

  return { ...data.data.attributes };
}

export async function getFaqCategoriesPage(slug) {
  return await cmsClient(`/faq-category/categoriesWithContent/${slug}`, {
    next: { revalidate: 240 },
  });
}

export async function getFaqByCategoryAndProduct(category, slug) {
  return await cmsClient(`/faqs/find-category-product/${category}/${slug}`, {
    next: { revalidate: 240 },
  });
}
