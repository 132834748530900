import { Container } from '@/components/core/flexbox';
import Solutions from '@/components/shared/Solutions';
import Wrapper from './Wrapper';
import theme from '@/utils/styles-variables';
import { Intro } from '@/components/shared';

function OurSolutions({ content }) {
  const { label, title, description, solutions } = content;
  return (
    <Wrapper>
      <Intro
        title={title}
        upperLabel={label}
        description={description}
        alignMobile="center"
        alignTablet="left"
        innerSpacer
      />
      <Container>
        <Solutions content={solutions} color={theme.colors.primary.light} />
      </Container>
    </Wrapper>
  );
}

export default OurSolutions;
