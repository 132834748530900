import { Container } from '@/components/core/flexbox';
import Wrapper from './Wrapper';
import ResultsInfo from '@/components/individuals/Results/ResultsInfo';
import MainInfo from '@/components/individuals/Results/MainInfo';
import { Intro } from '@/components/shared';

export default function Results({ content }) {
  const { upperLabel, title, subtitle, info, resultsInfo } = content;

  return (
    <Wrapper>
      <Intro title={title} upperLabel={upperLabel} description={subtitle} size="sm" innerSpacer />
      <Container>
        <MainInfo {...info} />

        {resultsInfo?.length > 0 && <ResultsInfo resultsInfo={resultsInfo} />}
      </Container>
    </Wrapper>
  );
}
