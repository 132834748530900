'use client';

import styled from 'styled-components';
import { useState } from 'react';
import cn from 'classnames';
import Image from 'next/image';

import getImgSrcWorkaround from '@/utils/images';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 17px;
`;

const ItemList = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  gap: 24px;
  margin-top: 36px;
`;

const Item = styled.div`
  height: auto;
  display: flex;
  gap: 24px;
  max-height: 52px;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
  &.active {
    max-height: none;
  }
`;

const Bar = styled.div`
  position: relative;
  height: 100%;
  width: 2px;
  background-color: #d8dbe1;
  box-sizing: border-box;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  &.active,
  ${Item} .active:before {
    border-left: 2px solid ${(props) => props.theme.colors.primary.default};
  }
`;

const ItemTitle = styled.h6`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 8px;

  color: #747986;
  transition: color 300ms ease-in-out;
  &.active {
    color: ${(props) => props.theme.colors.grey.dark};
  }
`;

const ItemDescription = styled.p`
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
  max-width: ${({ move }) => (move ? '295px' : 'unset')};
`;

const SelectedWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  width: 265px;
  height: 472px;
  margin: 0 auto;
`;

const Circle = styled.div`
  position: absolute;
  background-color: ${(props) => props.color};
  border-radius: 100%;
  transform: translateY(-50%);
  z-index: -1;
  width: ${({ move }) => (move ? 473 : 356)}px;
  height: ${({ move }) => (move ? 473 : 356)}px;
  top: ${({ move }) => (move ? 51 : 50)}%;
  left: ${({ move }) => (move ? '-25px' : 'unset')};
`;

const SelectedImage = styled.div`
  position: absolute;
  left: ${({ move }) => (move ? '215px' : '50%')};
  top: ${({ move }) => (move ? -55 : 12)}px;
  width: ${({ move }) => (move ? '473px' : '100%')};
  height: ${({ move }) => (move ? 610 : 444)}px;
  transform: translateX(-50%);
  z-index: 1;
  filter: drop-shadow(0px 30px 50px rgba(29, 56, 97, 0.1));

  opacity: 0;
  transition: opacity 300ms ease-in-out;

  img {
    object-fit: contain;
  }

  &.active {
    opacity: 1;
    transition: opacity 300ms 150ms ease-in-out;
  }
`;

function Tablet({ content, color, move }) {
  const [current, setCurrent] = useState(0);

  if (!content || !content.length) return null;

  const handleItemClick = (index) => {
    setCurrent(index);
  };

  return (
    <Wrapper data-testid="tablet">
      <ItemList>
        {content.map((item, index) => (
          <Item
            data-testid="tablet-item"
            key={item.id}
            order={index}
            onClick={() => handleItemClick(index)}
            className={cn({ active: current === index })}
          >
            <Bar className={cn({ active: current === index })} />
            <div>
              <ItemTitle data-testid="tablet-title" className={cn({ active: current === index })}>
                {item.title}
              </ItemTitle>
              <ItemDescription data-testid="tablet-desc" move={move}>
                {item.description}
              </ItemDescription>
            </div>
          </Item>
        ))}
      </ItemList>
      <SelectedWrapper>
        <Circle color={color} move={move} />
        {content.map((item, index) => (
          <SelectedImage key={item.id} className={cn({ active: current === index })} move={move}>
            {item.image && (
              <Image
                data-testid="tablet-image"
                src={getImgSrcWorkaround(item.image.data?.attributes?.url)}
                alt={item.image.data?.attributes?.alternativeText || ''}
                fill
                sizes="50vw"
              />
            )}
          </SelectedImage>
        ))}
      </SelectedWrapper>
    </Wrapper>
  );
}

export default Tablet;
