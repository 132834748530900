import Card from './Card';
import CardsWrapper from './CardsWrapper';
import Wrapper from './Wrapper';
import { Intro } from '@/components/shared';

export default function EndToEnd({ content }) {
  const { upperLabel, title, subtitle, programs } = content;

  return (
    <Wrapper>
      <Intro
        title={title}
        upperLabel={upperLabel}
        description={subtitle}
        alignMobile="left"
        innerSpacer
      />
      {programs && (
        <CardsWrapper>
          {programs.map((program) => (
            <Card key={program.title} {...program} />
          ))}
        </CardsWrapper>
      )}
    </Wrapper>
  );
}
