import styled from 'styled-components';

import { Container, Row } from '@/components/core/flexbox';

import ImageSwitch from '@/components/individuals/GetStarted/Steps/ImageSwitch';

import Number from './Number';

import DotsIcon from '@/public/assets/individuals/dots.svg';

const Switcher = ({ entries, setStep, step }) => (
  <Container data-testid="switcher">
    <Content>
      <ImageSwitch image={entries[step - 1]?.image} />
      <OptionsList>
        {entries.map((item, index) => (
          <OptionLabel
            data-testid="switcher-option"
            key={`get-started-${index}`}
            onClick={() => setStep(index + 1)}
            active={step === index + 1}
          >
            {index < entries.length - 1 && <Dots />}
            <Number active={step === index + 1}>{index + 1}</Number>
            {item.label}
          </OptionLabel>
        ))}
      </OptionsList>
    </Content>
  </Container>
);

export default Switcher;

const Content = styled(Row)`
  padding-top: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding-left: 88px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    justify-content: flex-start;
    max-height: 344px;
    margin: auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: auto;
    margin-bottom: -60px;
  }
`;

const OptionLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 41px;
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  position: relative;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 18px;
    max-width: 255px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Dots = styled(DotsIcon)`
  width: 32px;
  position: absolute;
  top: 37px;

  g {
    fill: ${(props) => props.theme.colors.primary.default};
  }
`;
