'use client';

import Image from 'next/image';
import Slider from 'react-slick';
import { usePathname } from 'next/navigation';

import { Col, Container, Row } from '@/components/core/flexbox';
import getImgSrcWorkaround from '@/utils/images';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import { LinkButton } from '@/components/core/Button';

import Wrapper from './Wrapper';
import TextCol from './TextCol';
import ConditionList from './ConditionList';
import Condition from './Condition';
import ConditionName from './ConditionName';

import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';
import { Paragraph, RichText } from '@swordhealth/ui-corporate';
import { Title } from '@/components/core';

const sliderSettings = {
  dots: true,
  speed: 500,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

function PelvicPainConditions({ content }) {
  const pathname = usePathname();
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const { title, description, link, linkLabel, pelvicCondition } = content;

  return (
    <Wrapper>
      <Container>
        <Row>
          <TextCol xs={12} xl={3}>
            <Title size="xl">{title}</Title>
            <RichText highlightLinks>
              <Paragraph>{description}</Paragraph>
            </RichText>
            <LinkButton
              $primary
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                trackButtonClicked({
                  action: ACTIONS.NAVIGATION,
                  buttonLocation: title,
                  buttonType: BUTTON_TYPES.NAVIGATION,
                  destination: link,
                  url: pathname,
                  buttonText: linkLabel,
                })
              }
            >
              {linkLabel}
            </LinkButton>
          </TextCol>
          <Col xs={12} xl={9}>
            <ConditionList>
              {isMobile ? (
                <Slider {...sliderSettings}>
                  {pelvicCondition.map((condition) => (
                    <div data-testid="mobile-item" key={condition.id}>
                      <Condition>
                        <Image
                          src={getImgSrcWorkaround(condition.image.data?.attributes.url)}
                          width={120}
                          height={120}
                          alt={condition.image.data.attributes.alternativeText || ''}
                        />
                        <ConditionName>{condition.name}</ConditionName>
                      </Condition>
                    </div>
                  ))}
                </Slider>
              ) : (
                pelvicCondition.map((condition) => (
                  <Condition key={condition.id}>
                    <Image
                      src={getImgSrcWorkaround(condition.image.data?.attributes.url)}
                      width={120}
                      height={120}
                      alt={condition.image.data.attributes.alternativeText || ''}
                    />
                    <ConditionName>{condition.name}</ConditionName>
                  </Condition>
                ))
              )}
            </ConditionList>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default PelvicPainConditions;
