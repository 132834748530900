import cn from 'classnames';
import styled from 'styled-components';

import { PAIN_CONDITIONS } from './Enums';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: unset;
  }
`;

const List = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-left: 59px;

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: ${(props) => props.theme.colors.grey.dark};
      position: absolute;
      left: 0;
      opacity: 0.2;
    }
  }
`;

const ItemTitle = styled.h4`
  color: #647390;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ItemDescription = styled.p`
  display: none;
  font-family: ${(props) => props.theme.font.family.body};
  color: ${(props) => props.theme.colors.grey[800]};
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

const ItemWrapper = styled.div`
  position: relative;
  max-width: 210px;
  transition: all 200ms ease;
  cursor: pointer;
  padding-bottom: 28px;

  &.open {
    pointer-events: none;

    ${ItemTitle} {
      margin-bottom: 8px;
      color: ${(props) => props.theme.colors.grey.dark};
      font-size: 30px;
      line-height: 40px;
      position: relative;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        font-size: 25px;
        line-height: 32px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        font-size: 20px;
        line-height: 24px;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
        &::before {
          content: '';
          display: block;
          width: 4px;
          height: 100%;
          position: absolute;
          left: -59px;
          background-color: ${(props) => props.theme.colors.grey.dark};
        }
      }
    }

    ${ItemDescription} {
      display: block;
      transition: all 200ms ease;
      height: auto;
    }
  }
`;

export default function PainDescriptions({ selectedPain, selectPain, options }) {
  if (!selectedPain && !selectPain && !options) return null;

  function getProp(prop, zone) {
    const a = options.find((opt) => opt.zone === zone);

    return a ? a[`${prop}`] : null;
  }

  return (
    <Wrapper>
      <List>
        {PAIN_CONDITIONS.map((pain) => (
          <ItemWrapper
            data-testid="pain-conditions"
            className={cn({ open: pain === selectedPain })}
            key={pain}
          >
            <ItemTitle onClick={() => selectPain(pain)}>{getProp('title', pain)}</ItemTitle>
            <ItemDescription> {getProp('description', pain)}</ItemDescription>
          </ItemWrapper>
        ))}
      </List>
    </Wrapper>
  );
}
