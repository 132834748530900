import styled from 'styled-components';

import { Container } from '@/components/core/flexbox';

const CardsContainer = styled(Container)`
  h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    padding-top: 24px;
    padding-bottom: 16px;
  }

  div {
    font-size: 16px;
    line-height: 28px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
  column-gap: 64px;
  row-gap: 48px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    column-gap: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    column-gap: 0;
    row-gap: 32px;
  }
`;

export default function CardsWrapper({ children }) {
  return (
    <CardsContainer>
      <Flex>{children}</Flex>
    </CardsContainer>
  );
}
