import styled from 'styled-components';
import Media from 'react-media';
import { Col, Row } from '@/components/core/flexbox';
import Slider from 'react-slick';
import MetricCard from '@/components/individuals/Results/MetricCard';
import theme from '@/utils/styles-variables';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const StyledSliderWrapper = styled.div`
  overflow: hidden;
  margin-top: 8px;

  .slick-slider {
    margin: 8px -10px 33px;

    .slick-dots {
      bottom: -40px;

      li {
        width: 8px;
        height: 8px;
        margin: 0 4px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          width: 8px;
          height: 8px;

          &:before {
            position: relative;
            content: '';
            width: 8px;
            height: 8px;
            background-color: transparent;
            border: 2px solid #a4aab6;
            border-radius: 50%;
            opacity: 1;
          }
        }
      }

      .slick-active button:before {
        background-color: #a4aab6;
      }
    }
  }
`;

const StyledRow = styled(Row)`
  gap: 24px;
  align-items: stretch;
  margin-top: 24px;

  ${Col} {
    flex: calc(50% - 24px);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    ${Col} {
      flex: calc(25% - 72px);
    }
  }
`;

const ResultsInfo = ({ resultsInfo }) => (
  <Media query={{ maxWidth: theme.breakpoints.max_sm }} defaultMatches={false}>
    {(matches) =>
      matches ? (
        <StyledSliderWrapper data-testid="results-info-mobile">
          <Slider {...settings}>
            {resultsInfo.map((item) => (
              <MetricCard key={item.id} {...item} />
            ))}
          </Slider>
        </StyledSliderWrapper>
      ) : (
        <StyledRow>
          {resultsInfo.map((item) => (
            <Col key={item.id} xs={6} lg={3}>
              <MetricCard {...item} />
            </Col>
          ))}
        </StyledRow>
      )
    }
  </Media>
);

export default ResultsInfo;
