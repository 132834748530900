import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

import Desktop from './Desktop';
import Tablet from './Tablet';
import Mobile from './Mobile';

function Solutions({ content, color, move }) {
  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px)`,
  );
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  if (!content || !content.length) return null;

  if (isMobile) {
    return <Mobile content={content} color={color} move={move} />;
  }
  if (isTablet) {
    return <Tablet content={content} color={color} move={move} />;
  }
  return <Desktop content={content} color={color} move={move} />;
}

export default Solutions;
