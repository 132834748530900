import styled from 'styled-components';

import { Col } from '@/components/core/flexbox';

const ModelAndDescriptionsWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  z-index: 1;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-right: 80px;
  }
`;

const ImageOuterWrapper = styled(Col)`
  order: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    order: 1;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  margin: auto;
  min-width: 160px;

  img {
    width: 316px;
    margin-right: 85px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    img {
      width: 255px;
      height: 645px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    order: 2;
    img {
      width: 150px;
      height: 379px;
    }
  }
`;

export { ModelAndDescriptionsWrapper, ImageOuterWrapper, ImageWrapper };
