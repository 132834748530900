import Image from 'next/image';
import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import { Wrapper } from './styles';

const Icon = ({ className, image, size = 48, iconSize = 32 }) => {
  const imageData = getImgData(image);

  if (!imageData) return null;

  return (
    <Wrapper className={className} $size={size}>
      <Image
        src={getImgSrcWorkaround(imageData.url)}
        alt={imageData.alternativeText || ''}
        width={iconSize}
        height={iconSize}
        data-testid="card-image"
      />
    </Wrapper>
  );
};

export default Icon;
