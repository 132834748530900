import styled from 'styled-components';
import Slider from 'react-slick';
import Image from 'next/image';

import Number from './Number';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  customPaging: (i) => <button type="button">{i + 1}</button>,
};

const StepsSlider = ({ entries }) => {
  if (!entries || !entries.length) return null;

  return (
    <Wrapper data-testid="mobile-slider">
      <Slider {...settings}>
        {entries.map((item, index) => (
          <Card key={item.label}>
            <ImageWrapper>
              <Image
                src={item.image.data.attributes.url}
                alt={item.image.data.attributes.alternativeText || ''}
                fill
                sizes="100vw"
              />
            </ImageWrapper>
            <Number isSelected={false}>{index + 1}</Number>
            <OptionLabel>{item.label}</OptionLabel>
          </Card>
        ))}
      </Slider>
    </Wrapper>
  );
};
export default StepsSlider;

const ImageWrapper = styled.div`
  width: 100%;
  height: 190px;
  position: relative;

  img {
    object-fit: cover;
    object-position: center;
  }
`;

const OptionLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 41px;
  font-family: ${(props) => props.theme.font.family.heading};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;

  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;

  .slick-slider {
    margin: 8px 0 33px;

    .slick-dots {
      bottom: -40px;
      text-align: left;
      margin-left: 24px;
      li {
        width: 8px;
        height: 8px;
        margin: 0 4px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          width: 8px;
          height: 8px;

          &:before {
            position: relative;
            content: '';
            width: 8px;
            height: 8px;
            background-color: transparent;
            border: 2px solid #a4aab6;
            border-radius: 50%;
            opacity: 1;
          }
        }
      }

      .slick-active button:before {
        background-color: #a4aab6;
      }
    }
  }
`;

const Card = styled.div`
  padding: 0 24px;
`;
