import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding-top: 136px;
  padding-bottom: 136px;
  background-color: ${(props) => props.theme.colors.neutral.default};
  overflow: hidden;
  z-index: 1;

  &:after {
    position: absolute;
    content: '';
    width: 489px;
    height: 489px;
    bottom: -95px;
    left: calc(50% + -60px);
    background: url('/assets/shapes/individuals_results_shape_small.svg') no-repeat center center;
    z-index: -1;

    @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
      width: 697px;
      height: 697px;
      left: calc(50% - 46px);
      bottom: -50px;
      background: url('/assets/shapes/individuals_results_shape.svg') no-repeat center center;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
      width: 842px;
      height: 842px;
      bottom: -402px;
      left: calc(50% + 192px);
      background: url('/assets/shapes/individuals_results_shape_large.svg') no-repeat center center;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    text-align: left;
  }
`;

export default Wrapper;
