'use client';

import { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { trackFaqClicked } from '@/utils/track';
import { SmallTitle } from '@/components/core';
import { CustomAccordionItem, CustomAccordionWrapper } from './styles';

const CustomAccordion = ({ accordion, full = false }) => {
  const pathname = usePathname();
  const [extendedAccordionItemIndex, setExtendedAccordionItemIndex] = useState(null);

  useEffect(() => {
    setExtendedAccordionItemIndex(null);
  }, [accordion]);

  if (!accordion) return null;

  const handleAccordionToggle = (i, title, category) => {
    const isExtended = extendedAccordionItemIndex === i;

    if (!isExtended) {
      trackFaqClicked({
        page_url: pathname,
        button_text: title,
        faq_category: category,
      });
    }
    setExtendedAccordionItemIndex(isExtended ? null : i);
  };

  return (
    <CustomAccordionWrapper full={full}>
      {accordion?.map((a, i) => (
        <CustomAccordionItem
          key={a.id}
          title={a.title}
          titleComponent={SmallTitle}
          expanded={i === extendedAccordionItemIndex}
          handleClick={() => handleAccordionToggle(i, a.title, a.category?.displayName)}
          data-testid="test"
        >
          {a.description}
        </CustomAccordionItem>
      ))}
    </CustomAccordionWrapper>
  );
};

export default CustomAccordion;
