'use client';

import styled from 'styled-components';
import Image from 'next/image';
import Slider from 'react-slick';
import { useState } from 'react';
import cn from 'classnames';

import getImgSrcWorkaround from '@/utils/images';

const Wrapper = styled.div`
  position: relative;

  & .slick-dots {
    bottom: unset;
    top: calc(112.5vw + 15px);
  }
  & .slick-dots li {
    width: unset;
    height: unset;
    margin: 0 4px;
  }
  & .slick-dots button {
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border: 2px solid #a4aab6;
    padding: 0;
    border-radius: 100%;
  }
  & .slick-dots button::before {
    content: none;
  }
  & .slick-dots .slick-active button {
    background-color: #a4aab6;
  }
`;

const Item = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding-top: calc(112.5vw + 72px);
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;

const SelectedWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 112.5vw;
  margin: 0 auto;
`;

const SelectedImage = styled.div`
  position: absolute;
  height: ${({ move }) => (move ? '114%' : 'unset')};
  top: ${({ move }) => (move ? -4 : 2.3)}%;
  bottom: 3.7%;
  left: 2%;
  right: 2.3%;
  z-index: 1;
  filter: drop-shadow(0px 30px 50px rgba(29, 56, 97, 0.1));
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  img {
    object-fit: contain;
  }

  &.active {
    opacity: 1;
    transition: opacity 300ms 150ms ease-in-out;
  }
`;

const Circle = styled.div`
  position: absolute;
  background-color: ${(props) => props.color};
  border-radius: 100%;
  width: 100%;
  height: 0;
  padding-top: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
`;

const ItemTitle = styled.h6`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 8px;
  text-align: center;
`;

const ItemDescription = styled.p`
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
  text-align: center;
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  customPaging: (i) => <button type="button">{i + 1}</button>,
};

function Mobile({ content, color, move }) {
  const [current, setCurrent] = useState(0);

  if (!content || !content.length) return null;

  const handleChange = (index) => {
    setCurrent(index);
  };

  return (
    <Wrapper data-testid="mobile">
      <ImageWrapper>
        <SelectedWrapper>
          <Circle color={color} />
          {content.map((item, index) => (
            <SelectedImage key={item.id} className={cn({ active: current === index })} move={move}>
              {item.image && (
                <Image
                  data-testid="mobile-image"
                  src={getImgSrcWorkaround(item.image.data?.attributes?.url)}
                  alt={item.image.data?.attributes?.alternativeText || ''}
                  fill
                  sizes="100vw"
                />
              )}
            </SelectedImage>
          ))}
        </SelectedWrapper>
      </ImageWrapper>
      <Slider beforeChange={(_, index) => handleChange(index)} {...settings}>
        {content.map((item, index) => (
          <Item key={item.id} order={index}>
            <ItemTitle data-testid="mobile-title">{item.title}</ItemTitle>
            <ItemDescription data-testid="mobile-description">{item.description}</ItemDescription>
          </Item>
        ))}
      </Slider>
    </Wrapper>
  );
}

export default Mobile;
