import styled from 'styled-components';
import { Container } from '@/components/core/flexbox';
import { H2, Label, UpperText } from '@/components/core/Title';

const Header = ({ upperLabel, title, description }) => (
  <Container>
    <Inner>
      {upperLabel && <UpperText data-testid="upperLabel">{upperLabel}</UpperText>}
      {title && <H2 data-testid="title">{title}</H2>}
      {description && <Label data-testid="label">{description}</Label>}
    </Inner>
  </Container>
);

export default Header;

const Inner = styled.div`
  max-width: 405px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 456px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
  }
`;
