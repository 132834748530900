'use client';

import styled from 'styled-components';
import Image from 'next/image';
import Slider from 'react-slick';
import { useState } from 'react';
import cn from 'classnames';

import getImgSrcWorkaround from '@/utils/images';
import Step from './Step';

const Wrapper = styled.div`
  position: relative;
  margin-top: 40px;
  margin-bottom: 30px;

  & .slick-dots {
    bottom: -30px;
  }
  & .slick-dots li {
    width: unset;
    height: unset;
    margin: 0 4px;
  }
  & .slick-dots button {
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border: 2px solid #a4aab6;
    padding: 0;
    border-radius: 100%;
  }
  & .slick-dots button::before {
    content: none;
  }
  & .slick-dots .slick-active button {
    background-color: #a4aab6;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;

const SelectedWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  width: 211px;
  height: 251px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.neutral.default};
  box-shadow: 0 20px 50px ${(props) => props.shadowColor || 'rgba(29, 56, 97, 0.1)'};
  padding: 13px;
  border-radius: 10px;
  margin: 0 auto;
`;

const SelectedImage = styled.div`
  position: absolute;
  z-index: 1;
  top: 13px;
  bottom: 13px;
  left: 13px;
  right: 13px;
  opacity: 0;
  transition: opacity 300ms ease-in-out;

  img {
    object-fit: contain;
  }

  &.active {
    opacity: 1;
    transition: opacity 300ms 150ms ease-in-out;
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

function MobileSteps({ steps, shadowColor }) {
  const [current, setCurrent] = useState(0);

  const handleChange = (index) => {
    setCurrent(index);
  };

  return (
    <Wrapper>
      <ImageWrapper>
        <SelectedWrapper shadowColor={shadowColor}>
          {steps.map((step, index) => (
            <SelectedImage key={step.id} className={cn({ active: current === index })}>
              <Image
                src={getImgSrcWorkaround(step.image.data.attributes.url)}
                alt={step.image.data.attributes.alternativeText || ''}
                fill
                sizes="100vw"
              />
            </SelectedImage>
          ))}
        </SelectedWrapper>
      </ImageWrapper>
      <Slider beforeChange={(_, index) => handleChange(index)} {...settings}>
        {steps.map((step) => (
          <Step key={step.id} {...step} isMobile />
        ))}
      </Slider>
    </Wrapper>
  );
}

export default MobileSteps;
