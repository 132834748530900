import SvgQuarterEllipse from './SvgQuarterEllipse';

export default function Decoration() {
  return (
    <>
      <SvgQuarterEllipse id="firstShape" />
      <SvgQuarterEllipse id="secondShape" />
      <SvgQuarterEllipse id="thirdShape" />
      <SvgQuarterEllipse id="fourthShape" />
    </>
  );
}
