import styled, { css } from 'styled-components';

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin-right: 15px;
  border: 1px solid ${(props) => props.theme.colors.primary.default};
  color: ${(props) => props.theme.colors.primary.default};
  border-radius: 50%;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primary.default : theme.colors.white};
  z-index: 100;

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.colors.primary.default};
      color: #fff;
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    margin-right: 25px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export default Number;
