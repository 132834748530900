import styled from 'styled-components';

import LargeCircle from '@/public/assets/shapes/individuals__blue_circle.svg';

export default styled(LargeCircle)`
  position: absolute;
  top: -191px;
  bottom: -120px;
  right: -215px;

  circle {
    fill: ${(props) => props.theme.colors.primary.light};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 684px;
    height: 684px;
    left: -340px;
    top: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 440px;
    height: 440px;
    left: 50vw;
    top: 10vh;
  }
`;
