'use client';

import { memo } from 'react';
import { TestimonialsCarousel as UITestimonialsSlider } from '@swordhealth/ui-corporate';

import { getImgData } from '@/utils/images';

const TestimonialsSlider = ({ quotes, ...props }) => {
  if (!quotes?.length) return null;

  return (
    <UITestimonialsSlider
      {...props}
      theme="white"
      quoteList={quotes.map(({ name, videoURL, picture, role, quote }) => {
        const imageData = getImgData(picture);

        return {
          variant: 'featured',
          quote,
          author: name,
          byline: role,
          image: imageData,
          video: videoURL
            ? {
                url: videoURL,
                mime: 'video/mp4',
              }
            : null,
        };
      })}
    />
  );
};

export default memo(TestimonialsSlider);
