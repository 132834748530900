import styled from 'styled-components';
import Image from 'next/image';
import { Col, Row } from '@/components/core/flexbox';

import QuoteIcon from '../../../public/assets/icons/quote.svg';

const CustomRow = styled(Row)`
  border-radius: 4px;
  overflow: hidden;
`;

const QuoteIconStyled = styled(QuoteIcon)`
  width: 27px;
  height: 20px;
  margin-bottom: 20px;
`;

const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 69%;

  img {
    object-fit: cover;
    object-position: center;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    padding-bottom: 46%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-bottom: 0;
    height: 100%;
  }
`;

const StyledQuote = styled.blockquote`
  font-size: 24px;
  line-height: 35px;
  font-weight: 500;
  font-family: ${(props) => props.theme.font.family.heading};
  color: ${(props) => props.theme.colors.grey.dark};
  display: block;
  margin-bottom: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    margin-bottom: 24px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

const StyledAuthor = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(props) => props.theme.colors.grey.dark};
  opacity: 0.9;
`;

const StyledRole = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.colors.grey.dark};
  opacity: 0.9;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-bottom: 0;
    height: 100%;
  }
`;

const CustomQuoteRow = styled(Row)`
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px 24px 40px;
  text-align: left;

  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    padding: 32px 32px 50px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding: 50px 88px 88px;
    gap: 50px;

    ${Col}:not(:first-child) {
      flex: calc(50% - 50px);
    }
  }
`;

const MainInfo = ({ image, sentence, author, role }) => (
  <CustomRow>
    <Col xs={12} md={6}>
      {image && (
        <StyledImageWrapper>
          <Image
            src={image.data.attributes.url}
            alt={image.data.attributes.alternativeText}
            fill
            sizes="(max-width: 767px)  100vw, 50vw"
          />
        </StyledImageWrapper>
      )}
    </Col>
    <Col xs={12} md={6}>
      <CustomQuoteRow>
        <Col xs={12}>
          <QuoteIconStyled />
          <StyledQuote>{sentence}</StyledQuote>
          <StyledAuthor>{author}</StyledAuthor>
          <StyledRole>{role}</StyledRole>
        </Col>
      </CustomQuoteRow>
    </Col>
  </CustomRow>
);

export default MainInfo;
