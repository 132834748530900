import styled from 'styled-components';

export const Wrapper = styled.figure`
  background-color: ${(props) => props.theme.colors.primary.light};
  border-radius: ${(props) => props.theme.borderRadius.full};
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
  }
`;
