import { Paragraph } from '../Paragraph';

const Content = ({ children }) => (
  <Paragraph
    highlightLinks
    markdownProps={{
      elements: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'ul',
        'ol',
        'li',
        'blockquote',
        'p',
        'em',
        'del',
        'code',
        'img',
        'a',
        'sup',
        'sub',
      ],
      components: {
        h1: 'h2',
        h2: 'h3',
      },
    }}
  >
    {children}
  </Paragraph>
);

export default Content;
