'use client';

import styled from 'styled-components';
import { useState } from 'react';
import cn from 'classnames';
import Image from 'next/image';

import getImgSrcWorkaround from '@/utils/images';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    'item0 image item1'
    'item2 image item3';
  row-gap: 20px;
  min-height: 580px;
`;

const SelectedWrapper = styled.div`
  grid-area: image;
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 472px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const Circle = styled.div`
  position: absolute;
  background-color: ${(props) => props.color};
  border-radius: 100%;
  width: 100%;
  height: 0;
  padding-top: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
`;

const SelectedImage = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  transform: translateX(-50%);
  filter: drop-shadow(0px 30px 50px rgba(29, 56, 97, 0.1));

  top: ${({ move }) => (move ? '-15px' : 0)};
  width: ${({ move }) => (move ? '400px' : '100%')};
  height: ${({ move }) => (move ? '680px' : '100%')};

  img {
    object-fit: contain;
  }

  &.active {
    opacity: 1;
    transition: opacity 300ms 150ms ease-in-out;
  }
`;

const Item = styled.div`
  grid-area: ${(props) => `item${props.order}`};
  margin-top: 40px;
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 24px;
  border-left: 1px solid ${(props) => props.theme.colors.grey.light};
  padding-bottom: 5px;
  transition:
    border-left 300ms ease,
    padding-left 300ms ease;
  cursor: pointer;

  &.active {
    cursor: unset;
  }
  &.active,
  ${Item}:hover & {
    border-left: 2px solid ${(props) => props.theme.colors.primary.default};
    padding-left: 23px;
  }
  ${Item}:hover &:not(.active) {
    border-left: 2px solid ${(props) => props.theme.colors.grey[500]};
    padding-left: 23px;
  }
`;

const ItemTitle = styled.h6`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 8px;
`;

const ItemDescription = styled.p`
  font-size: 16px;
  line-height: 28px;
  margin-top: 16px;
`;

function Desktop({ content, color, move }) {
  const [current, setCurrent] = useState(0);

  if (!content || !content.length) return null;

  const handleItemClick = (index) => {
    setCurrent(index);
  };

  return (
    <Wrapper>
      <SelectedWrapper>
        <Circle color={color} />
        {content.map((item, index) => (
          <SelectedImage key={item.id} className={cn({ active: current === index })} move={move}>
            {item.image && (
              <Image
                data-testid="desktop-image"
                src={getImgSrcWorkaround(item.image.data?.attributes?.url)}
                alt={item.image.data?.attributes?.alternativeText || ''}
                fill
                sizes="472px"
              />
            )}
          </SelectedImage>
        ))}
      </SelectedWrapper>
      {content.map((item, index) => (
        <Item key={item.id} order={index}>
          <ItemContent
            onClick={() => handleItemClick(index)}
            className={cn({ active: current === index })}
          >
            <ItemTitle data-testid="desktop-title">{item.title}</ItemTitle>
            <ItemDescription data-testid="desktop-desc">{item.description}</ItemDescription>
          </ItemContent>
        </Item>
      ))}
    </Wrapper>
  );
}

export default Desktop;
