import TestimonialsSlider from './TestimonialsSlider';
import styled from 'styled-components';

const StyledTestimonials = styled(TestimonialsSlider)`
  ${(props) => props.$spacerTop && 'padding-top: var(--section-padding-vertical);'}
`;

const Testimonials = ({ content, spacerTop = true }) => {
  if (!content) return null;

  const { sectionTitle, title, description, quotes } = content;

  return (
    <StyledTestimonials
      $spacerTop={spacerTop}
      sectionHeader={{
        title,
        description,
        label: sectionTitle,
        titleSize: '2xl',
      }}
      quotes={quotes}
    />
  );
};

export default Testimonials;
