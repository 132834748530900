import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 136px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 104px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 64px;
  }
`;

export default Wrapper;
