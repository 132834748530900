import styled from 'styled-components';

const Condition = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;
  row-gap: 50px;
  background-color: ${(props) => props.theme.colors.white};
  width: 216px;
  height: 280px;
  border-radius: 8px;
  padding: 24px 24px 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: unset;
    margin: 0 24px;
  }
`;

export default Condition;
