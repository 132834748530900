import styled, { css } from 'styled-components';
import { Accordion, AccordionItem } from '@/components/core/Accordion';

export const CustomAccordionWrapper = styled(Accordion)`
  max-width: 962px;
  margin: auto;
  position: relative;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 8px;
    border: 1px solid #e7eaf0;
  }

  ${(props) =>
    props.full &&
    css`
      max-width: 100%;
    `};
`;

export const customAccordionSpacings = css`
  padding: 16px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding: 27px 64px;
  }
`;

export const customAccordionItemStyles = css`
  padding: 0;
  position: relative;

  .title-wrapper {
    ${customAccordionSpacings}

    i {
      min-height: 30px;
      min-width: 30px;
      padding: 7.5px;
      background-color: ${(props) => props.theme.colors.primary.light};
      border-radius: 50%;

      &:before,
      &:after {
        background-color: ${(props) => props.theme.colors.grey.dark};
        height: 1px;
      }
    }

    &[aria-expanded='true'] {
      margin-bottom: 0;
    }
  }

  .content-wrapper {
    ${customAccordionSpacings};

    @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
      padding-top: 0;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
`;

export const CustomAccordionItem = styled(AccordionItem)`
  ${customAccordionItemStyles}

  &:first-child {
    border-top: 1px solid transparent;
  }
`;
