import styled from 'styled-components';

const ConditionName = styled.p`
  font-family: ${(props) => props.theme.font.family.heading};
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
`;

export default ConditionName;
