import styled from 'styled-components';
import MarkdownRichText from '@/components/shared/MarkdownRichText';

import ArrowUpIcon from '@/public/assets/icons/arrow-up.svg';

const DIRECTION_ENUM = {
  UP: 'Up',
  DOWN: 'Down',
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colors.white};
  min-height: 144px;
  margin: 0 10px;
  padding: 32px 24px 40px;
  border-radius: 4px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    height: 100%;
    min-height: auto;
    padding: 48px 40px;
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding: 50px 40px;
  }
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;

  svg {
    width: 14px;
    height: 16px;
    margin-right: 8px;
  }

  p {
    font-size: 22px;
    line-height: 34px;
    font-weight: 300;
    font-family: ${(props) => props.theme.font.family.heading};

    strong {
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;
    }

    a {
      color: ${(props) => props.theme.colors.primary.default};
      font-size: 60%;
      vertical-align: super;
      margin-left: 4px;
      padding-right: 4px;
      cursor: pointer;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    svg {
      width: 17px;
      height: 20px;
    }

    p {
      font-size: 30px;
      line-height: 40px;

      strong {
        font-size: 45px;
        line-height: 48px;
      }
    }
  }
`;

const Description = styled.h3`
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.grey[800]};
  font-family: ${(props) => props.theme.font.family.heading};
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const CustomColorArrow = styled(ArrowUpIcon)`
  color: ${(props) => props.theme.colors.primary.default};
`;

const ArrowDownIcon = styled(ArrowUpIcon)`
  transform: rotateX(180deg);
  color: ${(props) => props.theme.colors.primary.default};
`;

const ArrowIcon = ({ arrow }) => {
  switch (arrow) {
    case DIRECTION_ENUM.UP:
      return <CustomColorArrow />;
    case DIRECTION_ENUM.DOWN:
      return <ArrowDownIcon />;
    default:
      return '';
  }
};

export default function MetricCard({ title, description, arrow }) {
  return (
    <Wrapper>
      <ValueContainer>
        <ArrowIcon arrow={arrow} />
        <p>
          <MarkdownRichText elements={['a']}>{title}</MarkdownRichText>
        </p>
      </ValueContainer>
      <Description>{description}</Description>
    </Wrapper>
  );
}
