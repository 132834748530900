import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  padding-left: 48px;
  position: relative;
  font-family: ${(props) => props.theme.font.family.heading};

  &.is-open span {
    background: ${(props) => props.theme.colors.grey.dark};
    color: ${(props) => props.theme.colors.white};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-left: 46px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding-left: 52px;
  }
`;

const Index = styled.span`
  background: ${(props) => props.theme.colors.grey.dark};
  border: 1px solid ${(props) => props.theme.colors.grey.dark};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  line-height: 30px;
  color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition:
    color 300ms ease-in-out,
    background 300ms ease-in-out;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    background: transparent;
    color: ${(props) => props.theme.colors.grey.dark};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.grey.dark};
  }
`;

const ItemTitle = ({ title, index, className }) => {
  return (
    <Wrapper className={className}>
      <Index>{index}</Index>
      {title}
    </Wrapper>
  );
};

export default ItemTitle;
