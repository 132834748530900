import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: 136px;
  padding-bottom: 136px;
  background-color: ${(props) => props.theme.colors.neutral.default};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  & .slick-dots {
    bottom: -36px;
  }
  & .slick-dots li {
    width: unset;
    height: unset;
    margin: 0 4px;
  }
  & .slick-dots button {
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border: 2px solid #a4aab6;
    padding: 0;
    border-radius: 100%;
  }
  & .slick-dots button::before {
    content: none;
  }
  & .slick-dots .slick-active button {
    background-color: #a4aab6;
  }
`;

export default Wrapper;
